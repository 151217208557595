import { Placeholder } from "reactstrap";

export const Images = {
    logo : require("../assets/images/logo.png"),
    emailLabel : require("../assets/images/text_Enter your email.png"),
    presentLabel : require("../assets/images/Text_Present Your QR code.png"),
    submit : require("../assets/images/button_submit.png"),
    regBtn : require("../assets/images/7598663.png"),
    closeBtn : require("../assets/images/11450177.png"),
    done : require("../assets/images/button_done.png"),
}